
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
// import './css/jquery.mCustomScrollbar.min.css';
import './css/sources.css';
import './style.css';
import Home from './views/Home/Home';
import Details from './views/Details/Details';
import landscp from './images/landscp.png';
import loader from './images/mobi_preloader_icon.svg';
import { useEffect, useState } from 'react';
import { BASE_URL, lan } from './config';
import Profile from './partial/Profile/Profile';
import WishList from './views/WishList/WishList';
import { shallowEqual, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Seller from './views/SellerDetail/Seller';
import Active from './views/Avtivelist/Active';
import InactiveList from './views/InactiveList/InactiveList';
import FinishList from './views/Finishlist/FinishList';
import $ from "jquery";
import Success from './views/Success/Success';
import Failed from './views/Faild/Failed';
import SaveSeller from './views/SaveSeller/SaveSeller';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Helmet } from 'react-helmet';

function App() {
  let [clientToken,setClientToken]=useState("")
  const { landscape,isLoading,modalBack,listAdding} = useSelector(
    (state) => ({
      landscape: state.dataReducer.landscape,
      isLoading: state.dataReducer.isLoading,
      modalBack: state.dataReducer.modalBack,
      listAdding: state.dataReducer.listAdding,

    }),
    shallowEqual
  );
  function doOnOrientationChange() {
    if (document.getElementById("landscape")) {
      var x = document.getElementsByTagName("BODY")[0];
      switch (window.orientation) {
        case -90:

          x.style.backgroundColor = "black";
          document.getElementById("landscape").style.display = "block";
          document.getElementById("App").style.display = "none";
          break;
        case 90:

          x.style.backgroundColor = "black";
          document.getElementById("landscape").style.display = "block";
          document.getElementById("App").style.display = "none";
          break;
        default:
          x.style.backgroundColor = "#CED4D8";
          document.getElementById("landscape").style.display = "none";
          document.getElementById("App").style.display = "block";
          break;

      }
    }


  }
  useEffect(() => {


    //Listen to orientation change
    window.addEventListener('orientationchange', doOnOrientationChange);

  }, [])
  useEffect(() => {
    doOnOrientationChange()
  }, [landscape])

  useEffect(() => {
    if(isLoading){
      $(".outer").show();
    }
    if(!isLoading){
      $(".outer").fadeOut();
    }
  }, [isLoading])
//   const get_client_token = () => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const response = await fetch(BASE_URL+"/api/get-client-token", {
//           method: "POST", headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ "customer_id": JSON.parse(localStorage.getItem("login_data"))?._id }),
//         });
  
//         const client_token = await response.json();
//         setClientToken(client_token.client_token)
//         resolve(client_token);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   }

// useEffect(()=>{
//   get_client_token()
// },[])

  return (

    <>
    <PayPalScriptProvider
      options={{ "client-id": "AbEhoV_-T7_twt2Ls0aK4wO3N8C631a84ZJSZd_vQj7uCQGu1u8wZWyMXDDvdzJiXqwe4bhY8zBA8I6H","locale":"en_BA","disableFunding":"paylater","currency":"EUR"}}
    >
  <Helmet>
        <title>Mobi.ba - Kupovina i prodaja automobila u BiH</title>
        <meta property="og:title" content={"Kupovina i prodaja automobila u BiH"} />
        <meta property="og:description" content="Kupovina i prodaja automobila u BiH" />
        <meta property="og:image" content={`https://mobi.ba/mobi_share.png`} />
        <meta property="og:url" content={`https://mobi.ba`} />
        {/* <meta property="og:type" content="Car" /> */}
      </Helmet>
      
     <div id="App" className="App">
        <BrowserRouter basename='/'>
          <Routes >
            <Route exact path='/' element={<Home />} />
            <Route exact path='/:username/:id/:make' element={<Details />} />
            <Route exact path='/:username/:id' element={<Details />} />
            <Route exact path='/profile' element={<Profile />} />
            <Route exact path='/saved-listings' element={<WishList />} />
            <Route exact path='/:username' element={<Seller />} />
            <Route exact path='/:id/aktivni-oglasi' element={<Active />} />
            <Route exact path='/:id/neaktivni-oglasi' element={<InactiveList />} />
            <Route exact path='/:id/zavrseni-oglasi' element={<FinishList />} />
            <Route exact path='/saved-sellers' element={<SaveSeller />} />
            <Route exact path='/uspjesno-placanje' element={<Success />} />
            <Route exact path='/neuspjesno-placanje' element={<Failed />} />
            <Route path='/*' element={ <Navigate to="/" /> }/> 
          </Routes>
        </BrowserRouter>
      </div>
      {landscape && <div id="landscape" style={{ display: "none" }}>
        <div className='potrt'>
          <i className='portpic'><img src={landscp} /></i>
          {lan.rotate_potrait}
        </div>
      </div>}

  <div class="outer">
<div class="inner">
  <div class={isLoading?"":"cssanimation sequence fadeOutLeft"}>

  <img  src={loader}/>
  </div>
  {/* <!--<h1 class="cssanimation random fadeOutLeft">cssanimation</h1>--> */}
</div>
{listAdding&&<div className='loadertext'>
    {<h4 style={{color:"#f8b756",margin:0}}>Dodavanje oglasa...</h4>}
    {<p style={{color:"#f8b756",margin:0}}>Molimo za strpljenje</p>}
  </div>}
  </div>
      <ToastContainer style={{ zIndex: "99999" }} />
      {modalBack&&<div class="modalBackdrop"></div>}
    </PayPalScriptProvider>
    </>
  );
}

export default App;
